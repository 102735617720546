import React, {memo, useCallback, useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import {Card, CardBody, CardColumns, CardHeader, Col, Row} from 'reactstrap';
import MapSection from '../../shared/components/form-section/MapSection'
import Fade from '@material-ui/core/Fade';
import Snackbar from '@material-ui/core/Snackbar';
import BrandInfo from './BrandInfo';
import { getUpdateBrand, updateBrandList } from '../../actions/brandUpdateActions';
import SelectBrand from './SelectBrand';
import ImageGroup from './ImageGroup';
import AddBlockList from '../AddBlockList';
import Settings from './Settings';
import Address from './Address';
import Billing from './Billing';
import Contact from './Contact';
import Other from './Other';
import SubBrand from './SubBrand';
import Log from './Log';
import {API_URL, X_API_KEY, X_API_VERSION} from '../../conf';
import {getCookie} from '../../shared/utils/cookies';
import axios from 'axios';
import {axiosInterceptors} from '../../shared/utils/axiosInterceptors';

function BrandForm({ match }) {
    const [ brand, setBrand ] = useState({});
    const [ loading, setLoading ] = useState(true);
    const [ snackbarOpen, setSnackbarOpen ] = useState(false);
    const [updateBrand, setUpdateBrand ] = useState({});
    const [ errorMessage, setErrorMessage ] = useState({});
    const [ logInit, setLogInit ] = useState(false);
    const [ logList, setLogList ] = useState([]);
    // eslint-disable-next-line no-use-before-define
    const location = useLocation();


    const { params } = match;

    const getDataChild = useCallback((data) => {
        setUpdateBrand(item => ({ ...item, ...data }));
    }, [ JSON.stringify(updateBrand) ]);

    const handleUpdateBrand = useCallback(async (e) =>  {
        e.preventDefault();
        

        if(updateBrand.logo) {
            if(updateBrand.logo !== '' && typeof updateBrand.logo !== 'object') {
                delete updateBrand.logo
            }
        }

        if(updateBrand.image1) {
            if(updateBrand.image1 !== '' && typeof updateBrand.image1 !== 'object') {
                delete updateBrand.image1
            }
        }


        if(updateBrand.image2) {
            if(updateBrand.image2 !== '' && typeof updateBrand.image2 !== 'object') {
                delete updateBrand.image2
            }
        }

        if(updateBrand.image3) {
            if(updateBrand.image3 !== '' && typeof updateBrand.image3 !== 'object') {
                delete updateBrand.image3
            }
        }

        const formData = new FormData();


        if(brand.address && Object.entries(brand.address).length > 0) {
            Object.entries(brand.address).forEach(([key, value]) => {
                if(key !== 'display' && key !== 'full_address')
                formData.append(`address[${key}]`, value);
            });
        }
      

        const obj = Object.fromEntries(formData.entries());


        const brandUpdated = {
            ...obj,
            ...updateBrand,
        }



        const { error = {} } = await updateBrandList({id: params.id, ...brandUpdated})

        setLoading(true);

        if(Object.keys(error).length > 0 ) {
            setErrorMessage(error);
            setLoading(false);
            return
        }

        await editBrand(params.id)
        setSnackbarOpen(true)
        // setLoading(false);

        setTimeout(() => setSnackbarOpen(false), 2000)
    }, [ updateBrand ])

    const editBrand = useCallback(async (id) => {
        setLoading(true);
        const request = await getUpdateBrand(id)

        await setBrand(request);

        await setLoading(false)
    }, [ params.id ]);

    const useLocationParams = coordinates => {
        setUpdateBrand(item => ({ ...item, ...{
            longitude: coordinates[0],
            latitude: coordinates[1]
        }}));
    }

    const handleLog = async (e) => {
        e.preventDefault();
        let url = API_URL + '/brands/';

        url += `${params.id}/events`;
        // Axios Data
        const axiosData = {
            method: 'GET',
            url: url,
            headers: {
                //Accept: 'application/json',
                'Content-Type': 'application/json',
                'X-Api-Key': X_API_KEY,
                'X-Api-Version' : X_API_VERSION,
                'Authorization': 'Bearer ' + getCookie("_str")
            }
        };
        const axiosInstance = axios.create();
        axiosInterceptors(axiosInstance);
        // If everything is correct we dispatch our received action
        // otherwise our error action.
        return axiosInstance(axiosData)
            .then(response => {
                const { data } = response;
                setLogList(data);
                setLogInit(true);
            })
            .catch(err => {
                // eslint-disable-next-line no-console
                setLoading(false)
                console.log('AXIOS ERROR:', err.response);
                // dispatch(error(GET_LOGS_ERROR));
            });
    }

    useEffect(() => {
        if(params.id) {
            editBrand(params.id);
        }

        setUpdateBrand({})
        setBrand({})

        return () => setSnackbarOpen(false);
    }, [ params, location.pathname ]);

  return (
      <Col className="animated mt-5">
        <form onSubmit={handleUpdateBrand}>
            <div>
                <>
                    <Fade in={!loading} mountOnEnter unmountOnExit>
                        <Card>
                            <CardHeader>
                                <i className="fa fa-align-justify"></i>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setLogInit(false)
                                    }
                                }
                                    style={{
                                        background: '#0000',
                                        border    : 0,
                                        cursor    : 'pointer',
                                        color     : '#040D21',
                                        textShadow: !logInit ? '1px 0 0 currentColor' : null
                                    }}>
                                    Brand details
                                </button>

                                <span style={{
                                    margin: '0 5px'
                                }}>
                                    /
                                </span>

                                <button
                                    onClick={handleLog}
                                    style={{
                                        background: '#0000',
                                        border    : 0,
                                        cursor    : 'pointer',
                                        color     : '#040D21',
                                        textShadow: logInit ? '1px 0 0 currentColor' : null
                                    }}>
                                    Log
                                </button>
                            </CardHeader>
                            { !logInit && <CardBody>
                                <CardColumns className="form-sections">
                                    <>
                                        <BrandInfo
                                            brand={brand}
                                            getDataChild={getDataChild}
                                            error={errorMessage}
                                        />

                                        {
                                            (brand?.parent_brand || !brand?.sub_brands?.length > 0) &&
                                            <SelectBrand
                                                title={'Parent Brand'}
                                                brand={brand}
                                                getDataChild={getDataChild}
                                                error={errorMessage}
                                            />
                                        }

                                        {
                                            (brand.sub_brands && brand.sub_brands.length > 0) &&
                                            <SubBrand brand={brand} />
                                        }

                                        <ImageGroup
                                            brand={brand}
                                            getDataChild={getDataChild}
                                            error={errorMessage}
                                        />

                                    <Card>
                                        <CardBody>
                                            <div className="d-inline card-title">Tags</div>
                                            <AddBlockList
                                                getData={getDataChild}
                                                data={brand?.tags}
                                                title={'Tags'}
                                                error={errorMessage}
                                            />
                                        </CardBody>
                                    </Card>


                                    <SelectBrand
                                        title={'Contract Types'}
                                        brand={brand}
                                        getDataChild={getDataChild}
                                        error={errorMessage}
                                    />

                                    <SelectBrand
                                        title={'Categories'}
                                        brand={brand}
                                        getDataChild={getDataChild}
                                        error={errorMessage}
                                    />

                                    <Settings
                                        brand={brand}
                                        getDataChild={getDataChild}
                                        error={errorMessage}
                                    />
                                </>
                            </CardColumns>

                            {
                                <MapSection
                                    brand={brand}
                                    useLocationParams={useLocationParams}
                                />
                            }

                            <CardColumns className="form-sections">
                                {
                                    <>
                                        <Address
                                            brand={brand}
                                            getDataChild={getDataChild}
                                            error={errorMessage}
                                        />

                                        <Billing
                                            brand={brand}
                                            getDataChild={getDataChild}
                                            error={errorMessage}
                                        />

                                        <Contact
                                            brand={brand}
                                            getDataChild={getDataChild}
                                            error={errorMessage}
                                        />

                                        <Other
                                            brand={brand}
                                            getDataChild={getDataChild}
                                            error={errorMessage}
                                        />
                                    </>
                                }
                            </CardColumns>

                                <Row>
                                    <Col lg={12}>
                                        <button type="submit" className="btn btn-primary mt-3">Submit</button>
                                    </Col>
                                </Row>
                            </CardBody> }

                            {logInit && <Log data={logList} /> }
                        </Card>
                    </Fade>

                    { !logInit &&
                        <>
                            <Fade in={loading} mountOnEnter unmountOnExit>
                            <span style={{
                                position: 'fixed',
                                left: 0,
                                right: 0,
                                top: 0,
                                bottom: 0,
                                marginLeft: 150,
                                color: 'black',
                                background: 'white',
                                zIndex: 100,
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                fontSize: 40,
                                textAlign: 'center'
                            }}>Loading ...</span>
                            </Fade>

                            <Snackbar open={snackbarOpen} autoHideDuration={1000} message="Saved"
                                      action={
                                          <React.Fragment>
                                              <a className="text-white cursor-pointer mr-2" aria-label="close">
                                                  <i className="cil-x"/>
                                              </a>
                                          </React.Fragment>
                                      }
                            />
                        </>
                    }
                </>
            </div>
        </form>
      </Col>
  );
}

export default memo(BrandForm);